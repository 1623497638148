@import "../../../styles/base/abstracts";

.hbs-component-header.hbs-component-header {
  display: block;
  width: 100%;

  p {
    @include baseline-text;
    color: $c-text-light;
  }
}

.hbs-component-header--small {
  .hbs-component-header__title,
  p {
    @include mq($bp-desktop) {
      display: inline;
    }
  }

  .hbs-component-header__title {
    @include baseline-text($ff-sans, $fw-semibold);
    padding-right: $spacing-sm;
  }

  &.hbs-component-header--border-top {
    .hbs-component-header__inner {
      @include border(top, $c-border, $padding: 0);
    }
  }

  &.hbs-component-header--border-bottom {
    .hbs-component-header__inner {
      @include border(bottom, $c-border, $padding: 0);
      margin-bottom: $spacing-lg;
    }
  }

  &.hbs-component-header--border-hidden {
    .hbs-component-header__inner {
      @include unborder;
    }
  }

  .hbs-component-header__inner {
    @include padding($spacing-sm x);
    align-items: baseline;
  }
}

.hbs-component-header__inner {
  .hbs-component-header__cta,
  .hbs-cta-link--link {
    display: block;
    margin-top: $spacing-sm;

    @include mq($bp-tablet) {
      align-self: flex-end;
      margin-top: 0;
    }
  }
}

.hbs-component-header__inner {
  position: relative;
  @include mq($bp-tablet) {
    display: flex;
    justify-content: space-between;
  }
}

.hbs-component-header__wrapper {
  .hbs-component-header__description {
    // to allow for edit attributes dotted outline
    display: block;

    @include mq($bp-desktop) {
      display: inline-block;
    }
  }

  .hbs-component-header--small & {
    @include mq($bp-tablet) {
      width: $col-12;
      line-height: 0;
    }

    @include mq($bp-desktop) {
      width: $col-8;
    }
  }
  .hbs-component-header--large & {
    .hbs-component-header__title {
      max-width: 30em;
    }
    p {
      max-width: 40em;
    }
  }
}

.hbs-component-header__link {
  flex-shrink: 0;
  margin-left: $spacing-sm;
}

.hbs-component-header__link__text {
  margin-right: $spacing-sm;
}

.hbs-component-header--large {
  &.hbs-component-header--border-bottom {
    .hbs-component-header__inner {
      @include border(bottom, $c-border, $padding: 0);
      margin-bottom: $spacing-lg;
      padding-bottom: $spacing-sm;
    }
  }

  .hbs-component-header__title {
    @include h4;

    .hbs-global-align-center &,
    .hbs-global-align-wide & {
      @include mq($bp-desktop) {
        @include h4;
      }
    }
  }

  p {
    margin-top: $spacing-sm;
  }

  .hbs-component-header__inner {
    @include margin(x x $spacing-md);
    align-items: flex-end;
  }

  .hbs-multi-feed-tease-row__feed & .hbs-component-header__inner {
    margin-bottom: $spacing-sm;
  }
}

.hbs-component-header--anchored {
  .hbs-component-header__inner {
    @include padding(x x $spacing-xxs);
    border-bottom: $border-dark;
  }
}
